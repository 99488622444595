import React, { useContext, useEffect, useState } from 'react'
import { Link, RouteComponentProps } from "react-router-dom"
import VendorContext from 'src/contexts/VendorContext'
import injectSheet from 'react-jss';
import Button from 'src/components/Button';
import LoadingSpinner from 'src/components/LoadingSpinner';
import urls from 'src/urls';
import PageHeader from 'src/components/PageHeader';
import BackLink from 'src/components/BackLink';

const styles = (theme: Theme) => ({
  inputContainer: {
    marginTop: '1rem'
  },
  inputField: {
    marginTop: '0.5rem',
    display: 'block'
  }
})

const getFile = (e: any) => {
  if (e.target.files === null) {
    return null
  }
  return e.target.files[0]
}

type Values = {
  vendorId: string | undefined,
  excel: File | null,
  images: File | null
}

const validForm = (values: Values) => {
  return !!values.vendorId && !!values.excel && !!values.images
}

type P = {
  match: any
}

const ImportProducts: StyledPFC<P & RouteComponentProps, typeof styles> = (props) => {
  const vendorCtx = useContext(VendorContext)
  const [excel, setExcel] = useState(null as File | null)
  const [images, setImages] = useState(null as File | null)

  const vendorId = props.match.params.vendorId
  const vendor = vendorCtx.vendors.filter(v => v._id === vendorId)[0]
  if (vendor === undefined) {
    return null
  }

  const handleSubmit = async () => {
    const response: any = await vendorCtx.startImport({ vendorId, excel, images })
    props.history.push(
      urls.vendorProductImportProgress(vendorId, response.channel)
    )
  }

  const { classes } = props
  return (
    <>
      <BackLink url={urls.vendorPage(vendorId)} text={vendor.title} />

      <PageHeader>Importer produkter</PageHeader>
      <form onSubmit={(e) => {
        e.preventDefault()
        handleSubmit()
      }}>
        <div className={classes.inputContainer}>
          <label htmlFor="">
            Excel-fil
          </label>
          <input
            className={classes.inputField}
            type="file"
            onChange={(e) => setExcel(getFile(e))}
          />
        </div>
        <div className={classes.inputContainer}>
          <label htmlFor="">
            Bilde-zip
          </label>
          <input
            className={classes.inputField}
            type="file"
            onChange={(e) => setImages(getFile(e))}
          />
        </div>
        <Button
          disabled={!validForm({ vendorId, excel, images })}
          onClick={handleSubmit}
          type='primary'>
          Start Import
        </Button>
      </form>
    </>
  )
}

export default injectSheet(styles)(ImportProducts)